<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/Manager' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>学生管理</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/StudentList' }">学生列表</el-breadcrumb-item>
            <el-breadcrumb-item >{{StudentInfo.name}}</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-row>
                <!-- 学生基本信息 -->
                <el-col :span="12" type="flex">
                    <el-card>
                        <div slot="header">
                            <el-avatar shape="square" :size="100" fit="fit" src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"></el-avatar>
                        </div>
                        <div>
                            <div>
                                <div class="item_tag">
                                    <span>姓名：</span>
                                    <span>{{StudentInfo.name}}</span>
                                </div>
                                <div class="item_tag">
                                    <span>性别：</span>
                                    <span>{{StudentInfo.gender}}</span>
                                </div>
                                <div class="item_tag">
                                    <span>生日：</span>
                                    <span>{{StudentInfo.birthday}}</span>
                                </div>
                                <div class="item_tag">
                                    <span>联系方式：</span>
                                    <span>{{StudentInfo.phone}}</span>
                                </div>
                            </div>
                        </div>
                    </el-card>
                </el-col>
                <!-- 学生其他信息 -->
                <el-col :span="12" type="flex">
                    <el-card>
                        <div>
                            <div class="item_tag">
                                <span>紧急联系人：</span>
                                <span>{{StudentInfo.guardianbackup}}</span>
                            </div>
                            <div class="item_tag">
                                <span>紧急联系电话：</span>
                                <span>{{StudentInfo.guardianPhonebackup}}</span>
                            </div>
                                <div class="item_tag">
                                <span>4564654654685465</span>
                                <span>{{StudentInfo.mother}}</span>
                                <span>--</span>
                                <span>{{StudentInfo.mother}}</span>
                            </div>
                                <div class="item_tag">
                                <span>学生爸爸：</span>
                                <span>{{StudentInfo.guardianbackup}}</span>
                            </div>
                                <div class="item_tag">
                                <span>紧急联系人：</span>
                                <span>{{StudentInfo.guardianbackup}}</span>
                            </div>
                        </div>
                    </el-card>
                </el-col>
            </el-row>
            <el-row>
            </el-row>
        </el-card>
    </div>
</template>

<script>
export default {
    data() {
        return{
            StudentInfo:{}
        }
    },
    created(){
        this.GetParams()
    },
    methods:{
        GetParams(){
            this.StudentInfo = JSON.parse(this.$route.query.student)
            console.log(this.StudentInfo)
        }
    }
}
</script>


<style lang="less" scoped>

  .item_tag{
    text-align:center;
    margin-top: 10px;
  }
</style>